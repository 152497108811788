@import "style-guide";

.title {
  @include Title-Widget-font;

  margin-bottom: 8*$u;
}

.wrap {}

.form {}

.actions {
  display: flex;
}

.time-picker {
  margin-bottom: 4*$u;

  &__icon {
    margin-right: 2*$u;
  }
}

.action {
  font-weight: 500;

  &--cancel {
    width: 20%;
    margin-right: $u;
  }

  &--set {
    width: 80%;
    margin-left: $u;
  }
}
