@import "style-guide";

.form {
  height: 100%;
}

.apply {
  width: 100%;
}

.section {
  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }

  &__block {
    padding-bottom: 6*$u;
  }
}

.field {
  &--name {
    width: 50%;
  }

  &--group {
    width: 50%;
  }

  &--countries {
    width: 50%;
  }

  &--zips {
    width: 50%;
  }

  &--valid-date {
    width: 50%;
  }

  &--price-component {
    width: 50%;
  }

  &--repeat-strategy {
    width: 50%;
  }
}

.controls {
  margin-right: 2*$u;
}
