@import "style-guide";

.asset-tooltip {
  padding: 2*$u;
  color: $white;

  &--isDarkText {
    color: $slate;
  }
}

.row {
  white-space: nowrap;
}

.pin {
  &--isSelected {
    z-index: 1;
  }
}

.arrival {
  @include Asset-Pin-Arrival-font;

  padding: 0 $u $u;
}

.icon {
  display: inline-block;
  vertical-align: top;
  margin-right: $u;

  &:last-child {
    margin-right: 0;
  }
}

.carrier {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $u;
  font-weight: bold;
  line-height: 1.82;

  &__name {
    padding-left: $u;
    padding-right: $u;
  }
}

.utilization {
  display: inline-block;
  vertical-align: top;
  margin-right: 2*$u;

  @include Asset-Pin-Utilization-font;
}

.price {
  display: inline-block;
  vertical-align: top;
  margin-right: 2*$u;

  @include Map-Text-Bold-font;

  &--isLast {
    margin-right: 0;
  }
}

.footer {
  left: 0;
  right: 0;
  min-width: 18 * $u;
}
