@import "style-guide";

.wrap {
  padding: 8*$u;
}

.field {
  &--total-price {

  }
}

.head {
  padding: 5*$u 6*$u 0 6*$u;
  border-bottom: 1px solid $light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 18*$u;
}

.body {
  position: relative;
  min-height: 20*$u;
}

.receipt {
  padding: 4*$u 6*$u;
}

.actions {
  padding: 4*$u 6*$u;
  flex-grow: 1;
  display: flex;
  gap: 2 * $u;
}

.action {
  width: 50%;
}

.mini-actions {
  display: flex;
  gap: 2 * $u;
  padding-bottom: 5*$u;
}

.total-price-skeleton {
  @include base-skeletons;

  height: 6*$u;
  width: 28 * $u;
  margin-top: $u;
  margin-bottom: 5*$u;
}

.total-price {
  font-size: 30px;
  font-weight: bold;
  color: $charcoal;
  padding-top: $u;
  padding-bottom: 5*$u;
}

.loader {
  &__paranja {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    opacity: 0.8;
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
