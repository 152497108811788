@import "style-guide";

.timer {
  padding: 0 2*$u;
  border-radius: 8px;
  background-color: $charcoal;
}

.text {
  font-size: 12px;
  font-weight: bold;
  line-height: 4*$u;
  color: $white;
}
