@import "style-guide";

.zone-title {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.25;
  color: $orange;
  text-decoration: underline;
  cursor: pointer;
}

.content {
  display: flex;
  padding: 3*$u;

  &__left-icon {
    padding-right: 3*$u;
  }

  &__content {
    flex: 1;
  }
}

.text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}
