@import "style-guide";

.option {
  padding: $u;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-bottom: 2*$u;
}

.description {
  display: flex;
  gap: 2*$u;
  align-items: center;
}

.spacer {
  flex-grow: 1;
}

.price {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: $charcoal;
}
