@import "style-guide";

.route {
  margin-bottom: 4*$u;
}

.excluded-countries {
  margin-bottom: 4*$u;
}

.alert {
  margin-bottom: 2*$u;
}

.table {
  &--creation-details,
  &--move-details {
    margin-bottom: 4*$u;
  }

  &--carrier-cost {
    margin-bottom: 8*$u;
  }
}

.tabs {
  margin-bottom: 2*$u;
}

.entry-card {
  margin-bottom: 2*$u;
}

.papers-card {
  margin-top: 8*$u;
}

.separator {
  height: 1px;
  background-color: $light;
  margin: 3*$u 6*$u;
}

.pill {
  margin-right: $u;
}

.tooltip {
  &__inner {
    padding: 2*$u 3*$u;
    white-space: nowrap;

    @include TooltipDark-font;
  }
}

.info-table-row {
  &--origin,
  &--destination {
    margin-bottom: 3*$u;
  }

  &--carrier,
  &--price-offer,
  &--self-cost,
  &--additinal-services {
    margin-bottom: 2*$u;
  }

  &--shipper {
    margin-bottom: $u;
  }
}

.team-driver {
  margin-right: $u;
}
