@import "style-guide";

.row {

}

.icon {
  padding: $u 2 * $u 0;
}

.number {
  padding-bottom: $u;
  font-size: 10px;
  font-weight: bold;
  color: $white;
  text-align: center;
}
