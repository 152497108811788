@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field {
  &--user,
  &--dates {
    width: 50%;
  }
}

.selected-filters {
  padding: $u 0;
}
