@import "style-guide";

.widget {
  width: 100%;
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.checkbox {
  width: 100%;
}

.checkbox-group {
  padding-bottom: 4*$u;
}

.field {
  width: 100%;
}

.field-group {
  margin-bottom: 3*$u;
}

.combined-field {
  &--fee-value {
    @include left-field-in-group;
  }

  &--fee-type {
    @include right-field-in-group;
  }
}

.rate-matrix-spread-description {
  &__text {
    margin-bottom: 2 * $u;
  }

  table {
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $white;
      padding: $u;
    }
  }
}
