@import "style-guide";

$defaultHorizontalMargin: $u;
$rowHeight: 10 * $u;

.field {
  position: absolute;

  &--radius {
    @include control-shadow-on-map;

    width: calc(20% - #{$defaultHorizontalMargin});
    left: 0;

    @media screen and (max-width: 1600px) {
      width: calc(50% - #{$defaultHorizontalMargin});
    }
  }

  &--filters {
    @include control-shadow-on-map;

    width: calc(20% - #{2 * $defaultHorizontalMargin});
    left: calc(20% + #{$defaultHorizontalMargin});

    @media screen and (max-width: 1600px) {
      width: calc(50% - #{$defaultHorizontalMargin});
      left: calc(50% + #{$defaultHorizontalMargin});
    }
  }

  &--carrier {
    @include control-shadow-on-map;

    width: calc(60% - #{$defaultHorizontalMargin});
    right: 0;

    @media screen and (max-width: 1600px) {
      width: calc(50% - #{$defaultHorizontalMargin});
      top: $rowHeight;
      left: 0;
    }
  }

  &--exclude-countries {
    width: calc(35% - #{$defaultHorizontalMargin});
    top: $rowHeight;
    left: 0;

    @media screen and (max-width: 1600px) {
      top: 2*$rowHeight;
      left: 0;
    }
  }

  &--timezone-switcher {
    @include control-shadow-on-map;

    width: calc(30% - #{2 * $defaultHorizontalMargin});
    top: $rowHeight;
    right: calc(20% + #{$defaultHorizontalMargin});

    @media screen and (max-width: 1600px) {
      width: calc(50% - #{$defaultHorizontalMargin});
      right: 0;
    }
  }

  &--timeline-trigger {
    @include control-shadow-on-map;

    width: calc(20% - #{$defaultHorizontalMargin});
    right: 0;
    top: $rowHeight;
    background-color: $labelDefaultBackgroundColor;

    @media screen and (max-width: 1600px) {
      width: calc(30% - #{$defaultHorizontalMargin});
      top: 2 * $rowHeight;
      right: 0;
    }
  }
}

.warning-pill {
  @include control-shadow;

  margin-right: 2*$u;
  margin-bottom: 2*$u;
  height: 6 *$u;
}
