@import "style-guide";

.stepper {
  display: flex;
}

.back-button {
  margin-right: 2*$u;
}

.step {
  display: flex;
  align-items: center;
  margin-right: 4*$u;

  &--isSelectable {
    cursor: pointer;
  }

  &__number {
    min-width: 6*$u;
    width: 6*$u;
    height: 6*$u;
    border-radius: 50%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: $white;
    line-height: 6*$u;
    margin-right: 2*$u;
    background-color: $gray;

    &--isActive {
      background-color: $brand-accent;
    }

    &--isPrevStep {
      background-color: $brand-accent;
      background-image: url("../../images/tick.svg");
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: $slate;

    &--isActive {
      color: $charcoal;
    }
  }
}
