@import "style-guide";

.option {
  display: flex;
  flex-direction: row;

  &__icon {
    min-width: 4*$u;
    height: 4*$u;
    margin-right: 2*$u;
  }

  &__label {
    @include Input-Value-Normal-font;
  }
}
