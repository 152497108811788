@import "style-guide";

.axis {
  color: $gray;
  stroke-width: 1;

  &--line {
    color: $light;
  }
}

$acceptedColor: $brand-accent;
$rejectedColor: $tomato-red;
$estimatedColor: $gray;
$realColor: $orange;

.line-chart {
  stroke-width: 3px;
  fill: none;

  &--accepted {
    stroke: $acceptedColor;
  }

  &--rejected {
    stroke: $rejectedColor;
  }

  &--estimated {
    stroke: $estimatedColor;
  }

  &--real {
    stroke: $realColor;
  }
}

.bubbles {
  stroke-width: 3px;
  stroke: $white;

  &:hover {
    stroke: $charcoal;
  }

  &--accepted {
    fill: $acceptedColor;
  }

  &--rejected {
    fill: $rejectedColor;
  }

  &--estimated {
    fill: $estimatedColor;
  }

  &--real {
    fill: $realColor;
  }
}
