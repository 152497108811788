@import "style-guide";

.wrap {
  position: relative;
  height: $u;
}

.line {
  position: absolute;
  height: 2px;
  width: 100%;
  transition: width $defaultAnimationDuration;

  &--isProgress {
    background-color: $brand-accent;
  }

  &--isBackground {
    background-color: $light;
  }
}
