@import "style-guide";

.inner {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: $controlDefaultBorderRadius;

  &--isClickable {
    cursor: pointer;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.head {
  padding: 4*$u 5*$u 3*$u 4*$u;

  &--isOpened {
    border-bottom: 1px solid $light;
  }

  &--isClickable {
    cursor: pointer;
  }
}

.main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-grow: 1;
  margin-bottom: $u;

  &__money {
    @include TextBig-price-font;

    margin-right: 2*$u;
  }

  &__diff {
    margin-right: 2*$u;
  }

  &__main-details {

  }
}

.column {
  &__main {
    flex-grow: 1;
  }

  &__icon {
    margin-right: 4*$u;
  }

  &__status {
    margin-top: $u;
  }
}

.body {
  padding: 4*$u 5*$u;
}

.link {

}

.match-pill {
  margin-right: $u;
}
