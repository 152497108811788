@import "style-guide";

.wrap {
  display: flex;
  flex-direction: row;
}

.action {
  width: 50%;
  margin-right: $u;
  margin-left: $u;

  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }
}

.button {
  width: 100%;
  text-align: left;
}
