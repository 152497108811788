@import "style-guide";

.map-footer {
  position: absolute;
  bottom: 16 * $u;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  padding: 3*$u 5*$u;
  border-radius: 4 * $u;
  box-shadow: 0 1px 15px 0 $black-10-percent;
  background-color: $white;
}
