@import "style-guide";

.title {
  @include Input-Label-font;

  text-transform: uppercase;
}

.row {
  margin-bottom: 4*$u;
  display: flex;
  align-items: center;

  &__column {
    &--title {
      flex-grow: 1;
    }

    &--right-node {

    }
  }
}
