@import "style-guide";

.calendar {
  margin-right: 8*$u;

  &:last-child {
    margin-right: 0;
  }
}

.dropdown {

}

.input {
  position: relative;
}

$borderWidth: 2px;

.trigger {
  position: relative;
}

.overlay {
  display: flex;
  flex-wrap: nowrap;
}
