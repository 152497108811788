@import "style-guide";

$dividerHeight: 6 * $u;
$dividerColor: $light;
$dividerColorHover: $charcoal;

.tooltip-wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 40 * $u;
}

.divider {
  position: relative;
  min-height: $dividerHeight;

  &--isClickable {
    cursor: pointer;
  }

  &:hover,
  &--isToggled {
    .divider {
      &__icon {
        color: $dividerColorHover;
      }

      &__line {
        background-color: $dividerColorHover;
      }
    }
  }

  &__icon {
    position: absolute;
    top: $dividerHeight / 2;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $dividerColor;
    background-color: $white;
  }

  &__line {
    height: 1px;
    width: 100%;
    position: absolute;
    top: $dividerHeight / 2;
    left: 50%;
    background-color: $dividerColor;
    transform: translate(-50%, -50%);
  }
}

.option {
  cursor: pointer;
  overflow: hidden;
  user-select: none;

  &--isSelected,
  &:hover {
    background-color: $light-50-percent;
  }

  &:first-child {
    border-radius: $u $u 0 0;
  }

  &:last-child {
    border-radius: 0 0 $u $u;
    border-bottom: none;
  }
}

.separator {
  background-color: $light-30-percent;
  height: 2 * $u;
}
