@import "style-guide";

.inline-loader {
  width: 100%;
  height: 100%;
  background-image: url("./icons/three-dots.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.inner {
  box-sizing: border-box;
}
