@import "style-guide";

.cost {
  @include Table-Row-font;

  width: 20*$u;
}

$inputPaddingLeft: 3*$u;

.price {
  padding-left: $inputPaddingLeft;
}

.empty {
  padding-left: $inputPaddingLeft;

  @include Input-Value-Placeholder-font;
}
