@import "style-guide";

.wrap {
  display: flex;
  gap: 2 * $u;
  justify-content: space-between;
}

.geo-suggest {
  flex-grow: 1;
}
