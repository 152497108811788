@import "style-guide";

.content {

}

.table {
  &--id {
    margin-bottom: 4*$u;
  }

  &--carrier {
    margin-bottom: 8*$u;
  }

  &--waypoint {
    margin-bottom: 4*$u;
  }

  &--spot {
    margin-bottom: 4*$u;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
