@import "style-guide";

.wrap {
  display: flex;
}

.field {
  background-color: $inputDefaultBackgroundColor;
  border-radius: $controlDefaultBorderRadius;

  &--to {
    margin-left: 2*$u;
  }

  &--from {
    margin-right: 2*$u;
  }
}
