@import "style-guide";

.inner {

}

.rates {
  @include Input-Value-Normal-font;

  white-space: nowrap;
}

.price-details {
  padding: 20px 30px 30px;

  &__header {

  }

  &__list {
    @include Input-Value-Normal-font;

    margin-bottom: 4*$u;

    &--isLast {
      margin-bottom: 0;
    }
  }
}
