@import "style-guide";

.widget {
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.widget-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  &__widget {
    width: calc(50% - #{3 * $u});
  }
}

.wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.field {
  &__label {
    @include field-label;

    margin-bottom: $u;
  }

  &--name {
    width: 25%;
  }

  &--legal-form {
    width: 25%;
  }

  &--dispatcher {
    width: 25%;
  }

  &--key-manager {
    width: 25%;
  }

  &--country {
    width: 50%;
  }

  &--zip {
    width: 20%;
  }

  &--city {
    width: 30%;
  }
}
