@import "style-guide";

.widget {
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.widget-group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  &__widget {
    width: calc(50% - #{3 * $u});

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}

.history-table {
  margin-bottom: 2*$u;

  &:last-child {
    margin-bottom: 0;
  }
}

.history {
  padding: 6*$u 6*$u 7*$u 6*$u;
}

.details {
  padding: 4*$u 6*$u $u 6*$u;
}
