@import "style-guide";

.route-type-switcher {
  position: absolute;
  top: 2 * $u;
  left: 2 * $u;

  @include control-shadow-on-map;
}

.loader {
  top: 10*$u;
}

.route-point-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8*$u;
  user-select: none;
  transform: translate(-50%, -100%);
}
