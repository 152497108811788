@import "style-guide";

.additional-services-group {
  &__block {
    margin-bottom: 6*$u;
  }

  &__trigger {
    display: inline-block;
    user-select: none;
    cursor: pointer;
  }

  &__head {
    margin-bottom: 4*$u;
  }

  &__expand-icon {
    display: inline-block;
    margin-left: 2*$u;
  }

  &__title {
    display: inline-block;

    @include Title-Widget-font;
  }
}

.additional-service {
  display: flex;
  justify-content: space-between;
  padding: $u 0;
  border-bottom: 1px solid $light;

  &:last-child {
    border-bottom: none;
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    color: $charcoal;
  }

  &__cost {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: $light;
    margin-left: 2*$u;

    &--isActive {
      color: $charcoal;
    }
  }
}
