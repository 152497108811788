@import "style-guide";

.table {
  &__header {
    @include Table-Header-font;
    @include Table-Header-middle-column;

    text-align: left;

    &--id {
      @include table-column-auto-adjust-width;

      /* custom paddings */
      padding: 0 3*$u 2*$u 4*$u;
    }

    &--pickup {
      flex-grow: 1;
    }

    &--destination {
      flex-grow: 1;
    }

    &--orders-counter {
      @include table-column-auto-adjust-width;
    }

    &--total-price {
      @include table-column-auto-adjust-width;
    }

    &--valid-till {
      @include table-column-auto-adjust-width;
    }

    &--emission-class {
      @include table-column-auto-adjust-width;
    }

    &--team-drive {
      @include table-column-auto-adjust-width;
    }

    &--status {
      @include table-column-auto-adjust-width;

      /* custom paddings */
      padding: 0 4*$u 2*$u 3*$u;
    }
  }

  &__column {
    @include Table-Row-middle-column;

    &--id {
      @include table-column-auto-adjust-width;

      /* custom paddings */
      padding: 5*$u 3*$u 5*$u 4*$u;
    }

    &--pickup {
      flex-grow: 1;
    }

    &--destination {
      flex-grow: 1;
    }

    &--orders-counter {
      @include table-column-auto-adjust-width;
    }

    &--total-price {
      @include table-column-auto-adjust-width;
    }

    &--valid-till {
      @include table-column-auto-adjust-width;
    }

    &--emission-class {
      text-align: center;

      @include table-column-auto-adjust-width;
    }

    &--team-drive {
      text-align: center;

      @include table-column-auto-adjust-width;
    }

    &--status {
      @include table-column-auto-adjust-width;

      /* custom paddings */
      padding: 5*$u 4*$u 5*$u 3*$u;
    }
  }
}
