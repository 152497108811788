@import "style-guide";

.field {
  &--company-name,
  &--full-name,
  &--email,
  &--phone-number {
    width: 50%;
  }
}
