@import "style-guide";

.field {
  &--from-country-code,
  &--to-country-code,
  &--from-zip-code,
  &--to-zip-code,
  &--shipper,
  &--carrier {
    width: 50%;
  }
}

.button {
  width: 100%;
}

.form {
  height: 100%;
}

.selected-filters {
  padding: $u 0;
}
