@import "style-guide";

.widget {
  margin-bottom: 6*$u;

  &__inner {
    padding: 3*$u 4*$u;
    box-sizing: border-box;
    position: relative;
  }

  &__aggregates {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: no-wrap;
  }
}

.actions {
  margin-top: 2*$u;
  margin-bottom: 2*$u;
}

.filter-tags {
  min-height: 6*$u;
}

.aggregate {
  padding: 3*$u 7*$u 3*$u 3*$u;

  &__checkbox {
    margin-bottom: 2*$u;
  }

  &__price {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    color: $charcoal;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__text {
    @include Input-Label-font;

    text-transform: uppercase;
  }

  &__circle {
    display: inline-block;
    margin-right: $u;
  }
}

.unit {
  margin-right: 2*$u;
}

.dates {
  min-width: 52*$u;
}

.option {
  margin-right: 3*$u;
}

.not-number {
  color: $gray;
}

.message {
  padding: 6*$u;
  text-align: center;
}
