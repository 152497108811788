@import "style-guide";

.wrap {
  position: relative;
}

.document-table-wrap {
  position: relative;
  min-height: 100*$u;
}
