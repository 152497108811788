@import "style-guide";

.form {
  height: 100%;
}

.field {
  &--name {
    width: 65%;
  }

  &--number {
    width: 35%;
  }

  &--valid-till,
  &--activate-date {
    width: 50%;
  }

  &--file {
    margin-top: 5 * $u;
  }
}

.button {
  width: 100%;
}
