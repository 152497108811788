@import "style-guide";

.wrap {

}

.group {
  &--isGroup {
    padding-left: 6 * $u;
  }
}

.checkbox {
  padding: 2 * $u;
}
