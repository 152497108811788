@import "style-guide";

.header__controls {
  margin-right: 2*$u;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 6*$u 0;
}
