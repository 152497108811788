@import "style-guide";

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transform: translate(-50%, -100%);
}

.content {
  position: absolute;
  bottom: 3*$u;
  border-radius: 4px;
  padding: 4*$u;
  box-shadow: 0 1px 10px 0 $shadow-for-dark-base;
  background-color: $charcoal;
  transform: translateX(-50%);
  width: 34*$u;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: $u solid transparent;
    border-bottom: $u solid transparent;
    border-right: $u solid transparent;
    border-top: $u solid transparent;
    z-index: $z-index-tooltip;
    bottom: -2*$u;
    left: 50%;
    margin-left: -$u;
    border-top-color: $charcoal;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: left;

  &__icon {
    margin-right: $u;
    min-width: 4*$u;
  }

  &__text {
    font-size: 11px;
    font-weight: bold;
    line-height: 4*$u;
    color: $white;
  }
}
