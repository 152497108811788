@import "style-guide";

.header {
  padding-right: 6*$u;
  padding-left: 6*$u;
}

.steps {
  margin-bottom: 6*$u;
}
