@import "style-guide";

.input {
  display: flex;
  width: 100%;
  padding: (($u) - $inputBorderWidth) ((2 * $u) - $inputBorderWidth);
  border-radius: $controlDefaultBorderRadius;
  background-color: $inputDefaultBackgroundColor;
  border: solid $inputBorderWidth transparent;

  &--isEmpty {
    border-color: transparent;
  }

  &--isClickable {
    cursor: pointer;
  }

  &--hasWarning {
    border-color: $inputWarningBorderColor;
  }

  &--hasChanges {
    border-color: $inputWarningBorderColor;
  }

  &--hasError {
    border-color: $inputErrorBorderColor;
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }

  &--hasRightNode {
    padding-right: $u - $inputBorderWidth;
  }
}

.icon {
  width: 4*$u;
  height: 4*$u;

  &--isLeft {
    margin: $u 2*$u $u $u;
  }

  &--isRight {

  }
}

.label {
  flex-grow: 1;
  padding: $u;

  @include Input-Value-Normal-font;

  &--isEmpty {
    @include Input-Value-Placeholder-font;
  }

  &--isDisabled {
    color: $inputDisabledColor;
  }
}

.upload-trigger {
  font-size: 11px;
  font-weight: bold;
  line-height: 1.82;
  color: $charcoal;
  text-transform: uppercase;
}
