@import "style-guide";

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name {
  @include Input-Value-Normal-font;
  @include Text-overflow-ellipsis;

  padding-right: $u;
}

.indicator {
  flex-shrink: 0;
}

.utilization {
  padding-left: $u;

  @include Utilization-Normal-font;

  white-space: nowrap;
}

.spacer {
  flex-grow: 1;
}
