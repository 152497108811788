@import "style-guide";

.control {
  margin-right: 2*$u;

  &--new {
    margin-right: 0;
    margin-left: 2*$u;
  }
}
