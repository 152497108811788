@import "style-guide";

.route {
  margin-bottom: 6*$u;
}

$directionWidth: 2*$u;

.table {
  margin-bottom: 7*$u;

  &--shipper {
    margin-bottom: 2*$u;
  }

  &--isLast {
    margin-bottom: 10*$u;
  }
}
