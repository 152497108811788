@import "style-guide";

.cell {

}

.name {
  @include Table-Row-font;
}

.group {
  @include Table-Row-Secondary-font;
}
