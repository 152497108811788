@import "style-guide";

.wrap {
  position: relative;
  display: inline-block;
}

.overlay {
  opacity: 0.8;
  background: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
