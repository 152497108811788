@import "style-guide";

.form {
  height: 100%;
}

.button {
  &--add {
    width: 100%;
  }

  &--cancel {
    margin-right: 4*$u;
    width: 40%;
  }

  &--save {
    width: 60%;
  }
}

.buttons {
  display: flex;
}

.section {
  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }

  &__block {
    padding-bottom: 6*$u;
  }
}

.field {
  &--name {
    width: 50%;
  }

  &--group {
    width: 50%;
  }

  &--countries {
    width: 50%;
  }

  &--zips {
    width: 50%;
  }

  &--valid-date {
    width: 50%;
  }

  &--price-component {
    width: 35%;
  }

  &--repeat-strategy {
    width: 30%;
  }
}

.combined-field {
  &--days {
    @include left-field-in-group;
  }

  &--dates {
    @include left-field-in-group;
  }

  &--repeat-type {
    @include right-field-in-group;
  }

  &--impact-value {
    @include left-field-in-group;
  }

  &--impact-strategy {
    @include right-field-in-group;
  }
}

.field-group {
  &--rule {
    width: 65%;
  }

  &--schedule {
    width: 70%;
  }
}

.status {
  margin-right: 4*$u;
}
