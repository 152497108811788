@import "style-guide";

.inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.matrix {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;

  &--isHorizontalHeader {
    max-height: 10*$u;
  }
}

.header {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.36;
  color: $gray;
  text-transform: uppercase;

  &--isHorizontal {
    max-height: 10*$u;
  }

  &--isVertical {
    max-width: 14*$u;
  }
}

$tooltip-background: $charcoal;

.column {
  position: relative;
  flex: 1;
  border: solid 1px $slate-40-percent;

  &--isHeader {
    background-color: $black-20-percent;
  }

  &--isActive {
    background-color: $light-5-percent;
  }

  &--isActiveCell {

  }

  &--isShowTooltip {
    &::after {
      content: "";
      position: absolute;
      top: -$u;
      left: 50%;
      margin-left: -$u;
      width: 0;
      height: 0;
      border-top: $u solid $tooltip-background;
      border-right: $u solid transparent;
      border-left: $u solid transparent;
      z-index: $z-index-price-matrix-tooltip;
    }
  }

  &--isSelected {
    background-color: $light-10-percent;
  }

  &--isSelectedCell {
    background-color: $brand-20-percent;
  }
}

.tooltip {
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: $z-index-price-matrix-tooltip;

  &--isTopCenter {
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &--isTopLeft {
    left: 0;
    transform: translate(0, -100%);
  }

  &--isTopRight {
    left: 100%;
    transform: translate(-100%, -100%);
  }

  &__inner {
    position: relative;
    width: 26 * $u;
    border-radius: 4px;
    box-shadow: 0 1px 15px 0 $black-30-percent;
    background-color: $tooltip-background;
    margin-bottom: $u;
    padding: 2*$u 3*$u;
  }

  &__text {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.67;
    color: $light;
    text-align: center;
    white-space: nowrap;
  }
}

.offer {
  user-select: none;

  &--clickable {
    cursor: pointer;
  }

  &__icons {
    min-height: 4*$u;
    margin-bottom: 4*$u;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: $u;
  }

  &__price {
    font-size: 12px;
    line-height: 1.67;
    font-weight: bold;
    color: $white;
    min-height: 4*$u;
    text-align: center;

    &--green {
      color: $brand-accent;
    }

    &--secondary {
      font-size: 8px;
      line-height: 2.5;
    }

    &--expired {
      color: $slate;
    }
  }
}

.cell {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__spacer {
    &--2 {
      flex-grow: 2;
    }

    &--3 {
      flex-grow: 3;
    }
  }

  &__content {
    // nothing
  }
}

.loader {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
