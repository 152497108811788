@import "style-guide";

.wrap {

}

.prices {
  margin-bottom: 4*$u;
}

.history-table {
}
