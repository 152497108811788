@import "style-guide";

.widget {
  width: 100%;
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.field {
  width: 50%;
}

.field-group {
  margin-bottom: 3*$u;
}
