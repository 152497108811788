@import "style-guide";

.map {
  position: relative;
  height: 100%;
  background-color: $white;
  border: solid 0 $brand-dark;
  transition: border 0.3s;

  &--isEditing {
    border-width: 5px;
  }

  &--hasRedBorder {
    border-color: $tomato-red;
  }
}

.route-point-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8*$u;
  user-select: none;
  transform: translate(-50%, -100%);
}

.exclude-countries {
  position: absolute;
  top: 3 * $u;
  left: 3 * $u;
}

.loader {
  padding-top: 10*$u;
}
