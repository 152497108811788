@import "style-guide";

$progressBarHeight: 2px;

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: $progressBarHeight solid $light;
}

.column {
  &--isLeft {
    flex: 1;
  }
}

.close {
  display: flex;
  height: 8*$u;
  width: 8*$u;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  &:hover {
    border-radius: 4px;
    background-color: $light;
  }
}

.title {
  @include secondary-font;
  @include Title-Widget-font;

  box-sizing: content-box;
  padding: 5*$u 0;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  &__bar {
    height: $progressBarHeight;
    position: absolute;
    bottom: -1 * $progressBarHeight;
    left: 0;
    background-color: $brand-dark;
    transition: width 0.3s;
  }

  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.82;
    color: $brand-dark;
  }
}
