@import "style-guide";

.skeleton {
  &__box {
    @include base-skeletons;

    &--first {
      height: 4*$u;
      margin-bottom: 4*$u;
    }

    &--secondary {
      height: 21*$u;
      margin-bottom: 20*$u;
    }
  }
}
