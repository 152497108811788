@import "style-guide";

.inner {
  margin-bottom: 6*$u;
}

.row {
  display: flex;
  margin-bottom: 2*$u;
}

.column {
  &--isLeftColumn {
    height: 4*$u;
    margin-right: 2*$u;
  }

  &--isRightColumn {
    flex-grow: 1;
  }

  &--isWeight {
    @include SideBar-InnerLeftMenu-Item-Normal-font;

    font-weight: bold;
  }

  &--isDirection {
    @include Input-Value-Normal-font;
  }
}

.direction {
  position: relative;

  /*
  &::before {
    content: " ";
    position: absolute;
    top: 4*$u - 1px;
    bottom: 4*$u - 1px;
    left: 2*$u - 2px;
    width: 4px;
    background: linear-gradient(to bottom, $dodger-blue, $blaze-orange);
    opacity: 0;
  }

  &--isShow {
    &::before {
      opacity: 1;
    }
  }
   */
}

.placeholder {
  background-color: $light-30-percent;
  border-radius: $u;

  &__icon {
    width: 4 * $u;
    height: 4 * $u;
  }

  &__content {
    width: 100%;
    min-height: 4 * $u;
  }
}
