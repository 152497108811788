@import "style-guide";

.wrap {
}

.form {
  padding-bottom: 5*$u;
}

.receipt {
  margin-bottom: 5*$u;
}

.actions {
  display: flex;
}

.action {
  font-weight: 500;

  &--cancel {
    width: 20%;
    margin-right: $u;
  }

  &--assign {
    width: 80%;
    margin-left: $u;
  }
}

.tout-type-tabs {
  margin-bottom: 6 * $u;
}

.asset-type-tabs {
  margin-bottom: 4 * $u;
}

.table {
  &--utilization {
    margin-bottom: 5 * $u;
  }
}

.self-cost-prediction {
  min-height: 50 * $u;
  position: relative;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    background-color: $white;
  }

  &__loader {
    position: absolute;
    top: 10 * $u;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.option {
  border-bottom: 1px solid $light;

  &:last-child {
    border-bottom: none;
  }
}
