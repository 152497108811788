@import "style-guide";

.cluster {
  display: inline-block;
  transform: translate(-50%, -50%);
  padding: 2 * $u;
  background-color: $charcoal;
  border-radius: 3 * $u;

  &--theme-charcoal {
    background-color: $charcoal;
  }

  &--theme-slate {
    background-color: $slate;
  }

  &--theme-light {
    background-color: $light;
  }

  &--theme-brand-dark {
    background-color: $brand-dark;
  }
}
