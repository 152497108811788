@import "style-guide";

.picker {

}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2*$u;

  &__icon {
    margin-right: 2*$u;
  }

  &__label {
    @include Input-Label-font;

    text-transform: uppercase;
    margin-right: 4*$u;
  }

  &__value {
    font-size: 14px;
    line-height: 1.43;
    font-weight: bold;
    color: $charcoal;
  }
}

.presets {
  padding-top: $u;
}

.preset {
  display: inline-block;
  padding-right: 2*$u;
  text-decoration: underline;
  cursor: pointer;
  font-size: 10px;
  font-weight: 500;
  line-height: 2;
}
