@import "style-guide";

.control {
  &--change-rates-form {
    margin-left: 4*$u;
  }

  &--upload-csv {
    max-width: 60*$u;
  }
}

.filters-pills {
  &__item {
    margin-right: 2*$u;
    margin-bottom: 2*$u;
  }
}
