@import "style-guide";

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: left;

  &__tab {
    margin-right: 2*$u;

    &:last-of-type {
      margin-right: 0;
    }

    &--is-active {
      color: $brand-dark;
    }
  }
}
