@import "style-guide";

.filters-trigger {
  margin-right: 2*$u;
}

.sort-trigger {
  margin-right: 2*$u;
}

.warnings-filter {
  margin-right: 2*$u;
}
