@import "style-guide";

.field {
  &--units,
  &--cargo-weight,
  &--puck-up-ref-number,
  &--drop-off-ref-number {
    width: 50%;
  }
}

.combined-field {
  display: flex;
  width: 100%;

  &__full-width {
    flex-grow: 1;
  }

  &__input {
    &--unit-value {
      @include left-field-in-group;
    }

    &--unit-type {
      @include right-field-in-group;
    }
  }
}
