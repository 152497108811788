@import "style-guide";

.wrap {
  display: flex;
  gap: 2 * $u;
  justify-content: space-between;
}

.field {
  &--location {
    margin-bottom: 2*$u;
  }
}

.geo-suggest {
  flex-grow: 1;
}

.time-picker {
  position: relative;
}

.right-node {
  display: flex;
  justify-content: right;
  gap: 2*$u;
}

.time-interval {
  margin-left: $u;

  &--isHalfOpacity {
    opacity: 0.2;
  }
}
