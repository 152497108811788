@import "style-guide";

$numberWidth: 35 * $u;

.field {
  &--name {
    flex-grow: 1;
    width: calc(100% - #{$numberWidth});
  }

  &--number {
    max-width: $numberWidth;
    min-width: $numberWidth;
  }

  &--activate-date,
  &--valid-till,
  &--orders-left {
    width: 33%;
  }

  &--file {
    padding-top: $u;
    padding-bottom: 5 * $u;
  }
}
