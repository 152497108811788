@import "style-guide";

.wrap {
  background-color: $white;
  padding: $u $u $u 3*$u;
  border-radius: $controlDefaultBorderRadius;
}

.inner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
}

.flag {
  margin-top: $u;
}

.close {
  margin: $u;
  color: $gray;
  cursor: pointer;

  &:hover {
    color: $charcoal;
  }
}
