@import "style-guide";

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.model-and-plate-number {
  margin-right: 2*$u;

  @include Dropdown-ListBold-font;
  @include Text-overflow-ellipsis;

  &--isDisabled {
    color: $inputDisabledColor;
  }
}

.spacer {
  flex-grow: 1;
}

.price {
  @include Dropdown-ListBold-font;

  margin-right: 3 * $u;

  &--isDisabled {
    color: $inputDisabledColor;
  }
}

.reset {
  margin-right: 3 * $u;
}

.details {
  margin-right: $u;
}
