@import "style-guide";

.wrap {
  position: relative;
  width: 100%;
  min-height: 8*$u;
}

.container {
  position: absolute;
  top: 0;
  left: 0;

  &--isMeasuring {
    opacity: 0;
  }
}

.option {
  vertical-align: top;
  display: inline-block;
}

.toggle {
  vertical-align: top;
  display: inline-block;
}

.trigger {
  color: $slate;
  padding-right: 2*$u;
  line-height: 4*$u;
}

.notification {
  display: inline-block;
  vertical-align: top;
  margin-right: 2*$u;
}
