@import "style-guide";

.filters-trigger {
  margin-right: 2*$u;
}

.sort-trigger {
  margin-right: 2 * $u;
}

.status {
  margin-right: 2 * $u;
}

.alert {
  margin-bottom: 4 * $u;
}
