@import "style-guide";

.overlay {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 1px 15px 0 $black-10-percent;
  z-index: 1;
}

.option {
  padding: 2*$u 3*$u;
  cursor: pointer;
  white-space: nowrap;

  @include Text-overflow-ellipsis;
  @include Dropdown-ListBold-font;

  &:hover {
    background-color: $optionHoverBackgroundColor;
  }

  &--isSelected {
    background-color: $inputHoverBackgroundColor;

    &:hover {
      background-color: $inputHoverBackgroundColor;
    }
  }

  &--isDisabled {
    background-color: $inputDisabledBackgroundColor;
    cursor: not-allowed;

    &:hover {
      background-color: $inputDisabledBackgroundColor;
    }
  }
}
