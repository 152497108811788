@import "style-guide";

.widget {
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.widget-group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  &__widget {
    width: calc(50% - #{6 * $u});
    flex-grow: 1;
    margin-right: 6*$u;

    &:first-child {
      margin-left: 0;
    }
  }
}

.actions {
  position: absolute;
  right: 6*$u;
  top: 5*$u;

  &__cancel {}

  &__save {
    margin-right: 2*$u;
  }
}

.wrap {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.field {
  &--minimal-cost-for-shipment {
    width: 50%;
  }

  &--driver-salary-per-hour {
    width: 50%;
  }

  &--driver-working-day {
    width: 50%;
  }

  &--driver-rest-time {
    width: 50%;
  }

  &--layover-cost-per-hour {
    width: 50%;
  }

  &--truck-consumption {
    width: 50%;
  }

  &--reef-consumption {
    width: 50%;
  }

  &--green-shipment-cost {
    width: 50%;
  }

  &--pricing {
    width: 50%;
  }

  &--carrier-assignment {
    width: 50%;
  }

  &--tranziit-service-fee-value {
    width: 65%;
  }

  &--tranziit-service-fee-type {
    width: 35%;
  }
}

.tab {
  width: 100%;
  text-align: left;
  margin-bottom: 2*$u;
}
