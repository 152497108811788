@import "style-guide";

$headHeight: 10px;

.wrap {
  background-color: $white;
}

.tabs-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
}

.tab {
  position: relative;
  flex-grow: 1;
}
