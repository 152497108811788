@import "style-guide";

.wrap {
  display: flex;
}

.field {
  &--value {
    width: 38*$u;
  }

  &--type {
    width: 22*$u;
    margin-right: 4*$u;
  }
}
