@import "style-guide";

.wrap {
  position: relative;
}

.columns {
  display: flex;
  flex-direction: row;
  gap: 6 * $u;
}

$activeBorderWidth: 3px;

.tab {
  position: relative;
  flex-grow: 1;
  max-width: 39 * $u;
  margin-bottom: $u;
  cursor: pointer;
  user-select: none;

  &--isActive {
    &::after {
      content: " ";
      position: absolute;
      width: 100%;
      height: $activeBorderWidth;
      bottom: -$u;
      left: 0;
      background-color: $brand-accent;
    }
  }
}

.head {
  font-size: 14px;
  font-weight: bold;
  line-height: 4 * $u;
  margin-bottom: 2*$u;
}

.row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 900;
  line-height: 4 * $u;
}

.diff-wrap {
  position: absolute;
  left: 100%;
  top: -1px;
}

.diff {
  margin-left: 2 * $u;
}
