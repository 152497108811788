@import "style-guide";

.pagination {
  display: inline-flex;
  overflow: hidden;
  background-color: $white;
  border-radius: 4px 4px 0 0;

  @include control-shadow;
}
