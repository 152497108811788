@import "style-guide";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.text {

}

.action {
  &--start {
    width: 100%;
  }
}

.button-group {
  gap: 2 * $u;
}

.count {
  padding: 0 $u;
}

.status {
  text-align: right;
  color: $gray;

  &--is-warning {
    color: $orange;
  }
}

.time {
  font-size: 18px;
  font-weight: bold;
}

.message {
  font-size: 10px;
  font-weight: bold;
}

.already-started {
  font-size: 12px;
  font-weight: bold;
  color: $tomato-red;
  text-align: right;
  max-width: 18*$u;
}
