@import "style-guide";

.inner {
  border-radius: 4px;
  transition: box-shadow $defaultAnimationDuration;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.rates {
  @include Input-Value-Normal-font;

  white-space: nowrap;
}

.price-details {
  border-top: 1px solid $light;
  padding: 20px 30px 30px;

  &--isGreenTrack {
    border-color: $brand-accent;
  }

  &__header {

  }

  &__list {
    @include Input-Value-Normal-font;

    margin-bottom: 4*$u;

    &--isLast {
      margin-bottom: 0;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin-bottom: 4*$u;
}

.placeholder {
  padding: 6*$u 6*$u 2*$u 5*$u;
  display: flex;

  &__column {
    &--first {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &--second {
      flex-grow: 1;
    }

    &--third {
      width: 30%;
    }
  }

  &__box {
    border-radius: 2*$u;
    background-color: $light-30-percent;

    &--dates {
      width: 100%;
      min-height: 6*$u;
    }

    &--price {
      width: 100%;
      min-height: 6*$u;
      margin-bottom: $u;
    }

    &--rate {
      width: 30%;
      min-height: 2*$u;
      margin-bottom: $u;
    }
  }
}
