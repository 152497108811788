@import "style-guide";

.field {
  &--name {
    flex-grow: 1;
  }

  &--number {
    max-width: 35 * $u;
    min-width: 35 * $u;
  }

  &--activate-date,
  &--valid-till,
  &--orders-left {
    width: 33%;
  }

  &--file {
    padding-top: $u;
  }
}
