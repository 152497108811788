@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field {
  &--shipper,
  &--carrier,
  &--key-account-manager,
  &--dispatcher,
  &--trailer-type,
  &--order-type,
  &--transport-order-type,
  &--dates {
    width: 50%;
  }

  &--has-unpaid-shipment {
    margin-top: $u;
  }
}

.selected-filters {
  padding: $u 0;
}
