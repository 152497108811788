@import "style-guide";

.estimate {
  @include Route-Estimate-font;

  color: $black;
}

.loader {
  padding: $u;
  display: flex;
  align-items: center;
  gap: $u;
}

.loader-icon {

}

.loader-text {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
}
