@import "style-guide";

.table {
  position: relative;

  &__header {
    @include Table-Header-font;

    padding: 0 6*$u 2*$u 6*$u;
    text-align: left;

    &--name {
      flex-grow: 5;
    }

    &--cost {
      flex-grow: 1;
      min-width: 20*$u;
    }
  }

  &__column {
    &--name {
      flex-grow: 5;
      padding: 3*$u 6*$u;
    }

    &--cost {
      flex-grow: 1;
      min-width: 20*$u;
      padding: 3*$u 6*$u 3*$u 3*$u;
    }
  }
}
