@import "style-guide";

.header {
  &__controls {
    margin-right: $header-controls-right-margin;
  }
}

.controls {
  margin-top: 4*$u;
  margin-bottom: 6*$u;
}
