@import "style-guide";

.content {
  text-align: center;
  padding: 2*$u;
}

.total {
  @include SideBar-Offer-price-font;

  color: $charcoal;
  cursor: pointer;
}
