@import "style-guide";

.details {
  padding: 2*$u 4*$u 2*$u 6*$u;
  display: flex;
  cursor: pointer;
}

.icon {
  margin-top: 4*$u;
  margin-right: 4*$u;
  pointer-events: none;
  min-width: 6 * $u;
  min-height: 6 * $u;
}

.price {
  margin-top: 4*$u;
  margin-right: 2*$u;

  &__total {
    @include TextBig-price-font;

    color: $charcoal;
    white-space: nowrap;
  }

  &__description {
    text-align: right;
    font-size: 10px;
    font-weight: bold;
    line-height: 2;
    color: $charcoal;
  }
}

.price-details-trigger {
  margin-top: 4*$u;
}

.attributes {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  margin-left: 2*$u;
  margin-top: 4*$u;

  &__inner {
    display: flex;
    flex-flow: wrap;
    justify-content: right;
  }

  &__attribute {
    width: 4*$u;
    height: 4*$u;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: $u;

    &:last-child {
      margin-right: 0;
    }

    &--isUrgent {
      background-image: url("./images/icon-20-px-icn-urgent.svg");
    }

    &--isTeamDrive {
      background-image: url("./images/icon-20-px-icn-team-drive.svg");
    }

    &--isLayover {
      background-image: url("./images/icon-20-px-icn-layover.svg");
    }
  }
}

.emission-details {
  display: flex;
  flex-direction: column;
  margin-left: 2 * $u;

  &__row {
    text-align: right;
    margin-top: 2 * $u;
  }
}

.emission {
  &__icon {
    margin-right: 2 * $u;
  }

  &__value {
    line-height: 4 * $u;
    font-size: 14px;
    font-weight: bold;
    color: $charcoal;
  }
}

.emission-labels {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 2 * $u;
}
