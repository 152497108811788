@import "style-guide";

.map {
  position: relative;
  height: 100%;
  background-color: $white;
}

$markerWidth: 8*$u;
$markerHeight: 10*$u;

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $markerWidth;
  height: $markerHeight;
  user-select: none;
  transform: translate(-50%, -100%);
}

.estimate {
  @include Route-Estimate-font;

  color: $charcoal;
}
