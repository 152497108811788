@import "style-guide";

.created-date {
  @include Table-Row-Secondary-small-font;
}

.full-name {

}

.phone {
  @include Table-Row-Secondary-small-font;
}

.tooltip {
  padding: 2*$u;
  display: flex;

  &__avatar {
    margin-right: 2*$u;
  }

  &__info {

  }

  &__full-name {
    @include TextNotification-Text-font;
  }

  &__role {
    @include Notification-Number-font;
  }
}
