@import "style-guide";

.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.countries {
  @include control-shadow;

  margin-right: 2*$u;
  margin-bottom: 2*$u;
  background-color: $white;
  border-radius: $controlDefaultBorderRadius;
}

.country {
  @include control-shadow;

  margin-right: 2*$u;
  margin-bottom: 2*$u;
}

.countries-overlay {
  min-width: 100%;
}

.option {
  padding-left: 2*$u;
}
