@import "style-guide";

.block {
  position: relative;
  margin-bottom: 10*$u;

  &__title {
    margin-bottom: 6*$u;

    @include secondary-font;
    @include Title-Widget-font;
  }

  &__content {

  }

  &--isTimeWindow {
    position: relative;
    margin-bottom: 4*$u;
  }

  &--isRoute {
    margin-bottom: 13*$u;
  }

  &--isLane {
    margin-bottom: 13*$u;
  }

  &--dates {
    margin-bottom: 0;
  }
}

.address {
  margin-bottom: 20px;

  @include Input-Value-Normal-font;
}

.checkbox-label {
  @include Input-Value-Normal-font;
}

.field {
  &--date {
    width: 50%;
  }

  &--trailer-type {
    margin-bottom: 4*$u;
  }

  &--is-green-truck {
    margin-bottom: 4*$u;
  }

  &--trailer-mode {
    margin-bottom: 4*$u;
  }
}

.button-loader {
  &--isHide {
    visibility: hidden;
  }
}

.broker-time-window {
  margin-top: $u;
}

.help-panel {
  margin-bottom: 4*$u;
}

.help {
  &__title {
    font-weight: bold;
    margin-bottom: 4*$u;
  }

  &__content {
    margin-bottom: 4*$u;

    &--isLast {
      margin-bottom: 0;
    }
  }
}

.local-date-time-tooltip {
  width: 28*$u;
  text-align: center;
}

.wrap {
  padding-bottom: 12*$u;
}

.route-points-divider {
  margin-top: 4 * $u;
  margin-bottom: 9 * $u;
}

.lane-control-placeholder {
  min-height: 18 * $u;
}
