@import "style-guide";

.widget {
  width: 100%;
  padding: 0;
  margin-bottom: 6*$u;

  &__inner {
    padding: 6*$u 6*$u 2*$u 6*$u;
  }
}

.icon {
  margin-right: 2*$u;
}

.duration {
  margin-left: 3 * $u;
}

.field {
  width: 50%;

  &--range {
    width: 100%;
  }
}

.field-group {
  margin-bottom: 3*$u;
}
