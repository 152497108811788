@import "style-guide";

.wrap {
  white-space: nowrap;
}

.tooltip-content {
  padding: 2*$u;
}

.tooltip {
  display: inline-block;
  margin-right: $u;
}
