@import "style-guide";

.content-wrap {
  position: relative;
  display: inline-block;
}

.loader {
  position: absolute;
  right: 100%;
  padding-right: $u;
}
