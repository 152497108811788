@import "style-guide";

.wrap {
  width: 80*$u;
}

.content {
  min-height: 30*$u;
  text-align: center;
}

.description {
  @include Modal-Text-font;

  min-height: 8*$u;
  padding-bottom: $u;
  box-sizing: border-box;
}

.icon {
  height: 26*$u;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("./icons/illustration-csv.svg");
  margin-top: 8*$u;
  margin-bottom: 4*$u;
}

.status {
  @include Modal-Title-font;

  margin-bottom: 4*$u;
}

.status-icon {
  margin-bottom: 2*$u;
}

.loader {
  margin: 0 auto;
}

.action {
  min-width: 32*$u;
}
