@import "style-guide";

.form {
  padding-bottom: 5*$u;
}

.field {
  &--time-picker {
    padding-bottom: 4*$u;
  }
}

.actions {
  display: flex;
}

.action {
  font-weight: 500;

  &--cancel {
    width: 35%;
    margin-right: $u;
  }

  &--send {
    width: 65%;
    margin-left: $u;
  }
}

.time-window-pill-label {
  margin-left: $u;
}
