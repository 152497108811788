@import "style-guide";

.name {
  @include Table-Row-font;
}

.id {
  @include Table-Row-Secondary-small-font;
}

.cell {
  display: flex;
}

.notification {
  display: flex;
  align-items: center;
  margin-right: 4*$u;
}
