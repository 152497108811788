@import "style-guide";

.form {
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3*$u 6*$u;
  border-bottom: 1px solid $black-5-percent;
  background-color: #fff;

  &__title {
    @include Title-Widget-font;
  }

  &__space {
    flex-grow: 1;
  }

  &__status {
    margin-right: 4*$u;
  }
}

.button {
  &--add {
    width: 100%;
  }

  &--cancel {
    margin-right: 4*$u;
    width: 40%;
  }

  &--save {
    width: 60%;
  }
}

.buttons {
  display: flex;
}

.section {
  &__title {
    @include Title-Widget-font;

    margin-bottom: 6*$u;
  }

  &__block {
    padding-bottom: 6*$u;
  }
}

.field {
  &--name {
    margin-bottom: $u;
  }

  &--type {
    margin-bottom: 3*$u;
  }

  &--street1 {
    margin-bottom: 3*$u;
  }

  &--zip {
    width: 40%;
    margin-bottom: 7*$u;
  }

  &--city {
    width: 60%;
    margin-bottom: 7*$u;
  }

  &--legal-form {
    margin-bottom: 7*$u;
  }
}

.field-group {
  display: flex;
  flex-direction: row;

  &--rule {
    width: 70%;
  }

  &--schedule {
    width: 70%;
  }
}

.controls {
  margin-right: 2*$u;
}
