@import "style-guide";

.wrap {
}

.form {
  padding-bottom: 5*$u;
}

.alert {
  margin-bottom: 4*$u;
}

.footer-content {
  padding: 2*$u 0;
}

.actions {
  flex-grow: 1;
  display: flex;
  gap: 2 * $u;
}

.action {
  &--cancel {
    width: 50%;
  }

  &--assign {
    width: 50%;
  }
}

.submit-confirmation {
  margin-bottom: 4*$u;
}

.old-prices {
  margin-bottom: 2*$u;
}

.new-prices {
  margin-bottom: 4*$u;
}

.context-expiration-alert {
  margin: 0 6*$u 2*$u 6*$u;
}

.change-shipper-time-windows-alert {
  margin-bottom: 4*$u;
}

.route-point-fields {
  padding-bottom: 7*$u;
}

.route-points-divider {
  margin-bottom: 7*$u;
}
