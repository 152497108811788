@import "style-guide";

.wrap {
  text-align: right;
}

.controls {
  display: inline-block;
}

.option {
  display: flex;

  &__icon {
    min-width: 4*$u;
    height: 4*$u;
    margin-right: 2*$u;

    &--delete {
      background-image: url("./icons/icon-20-px-icn-delete.svg");
    }

    &--assign-admin {
      background-image: url("./icons/icon-20-px-icn-status-green.svg");
    }
  }

  &__label {

  }
}
