@import "style-guide";

.option {
  &--is-invalid {
    opacity: 0.25;
  }
}

.asset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.model-and-plate-number {
  @include Dropdown-ListBold-font;

  padding-right: 2*$u;
}

.price {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: $slate;
  padding-right: 2*$u;
}

.location {
  @include Dropdown-ListBold-font;
}

.utilization {
  display: flex;
  justify-content: flex-end;

  &__label {
    padding-left: $u;

    @include Utilization-Normal-font;
  }
}

.link {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2 * $u;

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2 * $u;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__trigger {
    line-height: 4*$u;
  }

  &__text {
    flex-grow: 1;
    white-space: normal;
  }
}
