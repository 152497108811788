@import "style-guide";

.content {

}

.table {
  &--carrier {
    margin-bottom: 4*$u;
  }

  &--move {
    margin-bottom: 4*$u;
  }

  &--carrier-cost {

  }

  &:last-of-type {
    margin-bottom: 0;
  }
}
