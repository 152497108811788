@import "style-guide";

.offer {
  border-radius: 4px;
  background-color: $white;
  transition: box-shadow 0.3s;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 4*$u;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
  }
}

.offers {
  margin-bottom: 6*$u;
}

.subtitle {
  @include Title-Widget-font;

  margin-bottom: 4*$u;
}

.inner {
  margin-bottom: 10*$u;
}

.quick-actions {
  margin-bottom: $u;
}

.progress-bar {
  margin-bottom: 4*$u;
}

.actions {
  display: flex;

  &__action {
    width: 50%;

    &--additional-services {
      flex-grow: 1;
      margin-right: 2 * $u;
    }
  }
}

.inner-button {
  width: 100%;
}
