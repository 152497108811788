@import "style-guide";

.location {
  transform: translate(-50%, -100%);
}

.control {
  position: absolute;
  top: 2*$u;
  left: 2*$u;
  right: 2*$u;
}

$rowHeight: 10 * $u;

.loader {
  margin-bottom: $u;

  &__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 2*$rowHeight;
    text-align: center;

    @media screen and (max-width: 1600px) {
      top: 3*$rowHeight;
      left: 0;
    }
  }
}

.route-point-pin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8*$u;
  height: 10*$u;
  user-select: none;
  transform: translate(-50%, -100%);
}
