@import "style-guide";

.inner {
  min-height: 105 * $u;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6*$u;

  &__tabs {

  }

  &__sort {

  }
}

.filters {
  &__button {
    margin-right: 2*$u;
  }

  &__count {
    padding: 0 $u;
  }
}

.bid {
  margin-bottom: 2 * $u;
}
