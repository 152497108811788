@import "style-guide";

.flag {
  vertical-align: top;
  margin-right: 2*$u;
}

.country {
  vertical-align: top;

  @include Table-Row-font;
}

.address {
  @include Table-Row-Secondary-small-font;
}
