@import "style-guide";

.partner {
  @include TableRowPrimary-Link-font;

  text-decoration: underline;
}

.placeholder {
  @include Table-Row-Secondary-small-font;
}
