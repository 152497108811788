@import "style-guide";

.form {
  display: flex;
  align-items: center;
  justify-content: left;
}

.count {
  padding: 0 $u;
}

.pill {
  margin-right: $u;
}

.dates {
  margin-right: 4*$u;
}

.input-dates {
  min-width: 43*$u;
}
