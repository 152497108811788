@import "style-guide";

.form {
  height: 100%;
}

.button {
  width: 100%;
}

.field-group {
  margin-bottom: 8 * $u;

  &__title {
    @include Title-Widget-font;
    @include secondary-font;

    margin-bottom: 6 * $u;
  }

  &--isLast {
    margin-bottom: 0;
  }
}

.field {
  &--key-account-manager {
    width: 50%;
  }

  &--emission-standard {
    width: 30%;
  }

  &--trailer-type {
    width: 70%;
  }

  &--dispatcher {
    width: 50%;
  }

  &--dates {
    width: 50%;
    padding-right: 3 * $u;
  }
}

.time-picker {
  margin-bottom: 4 * $u;
}
