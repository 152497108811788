@import "style-guide";

.offer {
  margin-bottom: 6*$u;
}

.block {
  margin-bottom: 8*$u;

  &__title {
    margin-bottom: 6*$u;

    @include secondary-font;
    @include Title-Widget-font;
  }

  &__content {
    margin-bottom: 6*$u;
  }
}

.actions {
  display: flex;

  &__action {
    &--place-and-next-new-order {
      margin-right: 2 * $u;
    }

    &--place {
      flex-grow: 1;
    }
  }
}

.inner {
  margin-bottom: 10*$u;
}

.shipments-card {
  margin-bottom: 2*$u;
}

.contacts-card {
  margin-bottom: 2*$u;
}

.contacts-card-title {
  @include base-font;

  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}
