@import "style-guide";

.field {
  &--trailer-type {
    width: 75%;
  }

  &--emission-class {
    width: 25%;
  }

  &--location {
    width: 75%;
  }

  &--radius {
    width: 25%;
  }

  &--date {
    width: 50%;
  }

  &--limit {
    width: 50%;
  }

  &--external-lane-id {
    width: 50%;
  }
}

.actions {
  width: 100%;
  display: flex;
  gap: 4 * $u;

  &__action {
    width: 50%;
  }
}
