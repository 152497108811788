@import "style-guide";

.chart {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container {
  position: relative;
  padding-top: 50%;
}
