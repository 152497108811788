@import "style-guide";

.wrap {
  min-width: 24*$u;
}

.creator {
  @include TableRowPrimary-Link-font;

  text-decoration: underline;
}

.date {
  @include Table-Row-font;
}
