@import "style-guide";

.wrap {
  min-width: 6*$u;
  text-align: center;
}

.money {
  @include Table-Row-font;
}
