@import "style-guide";

.field {
  &--trailer-type {
    width: 75%;
  }

  &--emission-class {
    width: 25%;
  }

  &--location {
    width: 75%;
  }

  &--radius {
    width: 25%;
  }

  &--date {
    width: 50%;
  }

  &--limit {
    width: 50%;
  }

  &--external-lane-id {
    width: 50%;
  }
}
