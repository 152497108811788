@import "style-guide";

.container {}

.tabs {
  margin-bottom: 4*$u;
}

.route {
  margin-bottom: 6*$u;
}

.table {
  margin-bottom: 4*$u;
}

.shipment-card {
  margin-bottom: 4*$u;
}

.waypoint-card {
  margin-bottom: 4*$u;
}

.excluded-countries {
  margin-bottom: 5*$u;
}

.separator {
  height: 1px;
  background-color: $light;
  margin: 3*$u 6*$u;
}

.bids {

}

.bid {
  margin-bottom: 2*$u;
}

.empty-prices {
  padding-right: 6*$u;
}
